// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-amenities-js": () => import("./../../../src/pages/Amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/Footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-js": () => import("./../../../src/pages/Insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-main-nav-bar-js": () => import("./../../../src/pages/MainNavBar.js" /* webpackChunkName: "component---src-pages-main-nav-bar-js" */),
  "component---src-pages-nav-bar-js": () => import("./../../../src/pages/NavBar.js" /* webpackChunkName: "component---src-pages-nav-bar-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

